import * as React from 'react';
import MessageBox from '../../components/MessageBox';
import FeedbackModule from '../../components/FeedbackModule';
import * as classNames from '../../assets/css/pages/uninstall_success.module.css';
import {useState} from 'react';

const CommerceExtUninstallPage = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const onSubmit = () => {
        setIsSubmitted(true);
    };

    return (
        <div className={classNames.container}>
            <MessageBox isSubmitted={isSubmitted} />
            <FeedbackModule onSubmit={onSubmit} isSubmitted={isSubmitted} />
        </div>
    );
};

export default CommerceExtUninstallPage;
