import React, {useEffect, useState} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import * as classNames from './css/feedbackModule.module.css';
import {getRapid} from '../../services';

export default function Card(props) {
    const {content} = useStaticQuery(dataQuery);
    const {isSubmitted, onSubmit} = props;

    const initialCheckBoxesMap = content.items.reduce((acc, itemText) => {
        return {
            ...acc,
            [itemText]: false
        };
    }, {});
    const [checkBoxesMap, setCheckBoxesMap] = useState(initialCheckBoxesMap);
    const [textAreaVal, setTextAreaValue] = useState('');
    let rapidInstance = getRapid(content.config.rapid.init);

    const updateCheckbox = ({name, checked}) => {
        setCheckBoxesMap((currentMap) => ({...currentMap, [name]: checked}));
    };

    const handleTextAreaChange = (event) => {
        const value = event?.target?.value || '';
        const sanitized = value.replaceAll(/[&/\\#+()$~%^":*<>{}]/g, '');
        setTextAreaValue(sanitized);
    };

    const onClickHandler = () => {
        const checkedNames = [];
        const ll4Object = {};
        for (const name in checkBoxesMap) {
            if (checkBoxesMap[name]) checkedNames.push(name);
        }

        if (textAreaVal) ll4Object.ll4 = textAreaVal;

        rapidInstance.beaconClick('user-reaction', 'uninstall-dislike', 1, {
            itc: 1,
            elm: 'reaction-dislike',
            elmt: checkedNames,
            ...ll4Object
        });
        onSubmit();
    };

    return (
        <div className={isSubmitted ? classNames.hiddenContainer : classNames.container}>
            <div className={classNames.intro}>
                {content.intro}
                <br />
                <span style={{fontWeight: 700}}>{content.selectAll}</span>
            </div>
            <div>
                {content.items.map((itemText, index) => {
                    return (
                        <div className={classNames.checklistItem} key={index}>
                            <input
                                className={classNames.checkBox}
                                type="checkbox"
                                id={index}
                                name={itemText}
                                value={itemText}
                                onChange={(e) => updateCheckbox(e.target)}
                            />
                            <label style={{margin: 0}} htmlFor={index}>
                                {itemText}
                            </label>
                        </div>
                    );
                })}
            </div>
            <textarea
                className={classNames.feedbackTextArea}
                id="feedbackTextArea"
                name="feedbackTextArea"
                rows="4"
                cols="50"
                placeholder={content.placeholder}
                maxLength="500"
                value={textAreaVal}
                onChange={(e) => handleTextAreaChange(e)}
            ></textarea>
            <div className={classNames.submitButton}>
                <input
                    className={classNames.submitButtonText}
                    type="submit"
                    value={content.buttonText}
                    onClick={onClickHandler}
                />
            </div>
        </div>
    );
}

export const dataQuery = graphql`
    query {
        content: feedbackModuleJson {
            intro
            selectAll
            items
            placeholder
            buttonText
            config {
                rapid {
                    init {
                        spaceid
                        keys {
                            pt
                            ver
                            pg_name
                            pct
                        }
                    }
                }
            }
        }
    }
`;
